<template>
  <div>
    <div style="background-color: white; padding: 20px; height: 100%">
      <div style="display:flex;justify-content: space-between">
        <div>
          <select-area @areaListId="getAreaListId" :clear="clear" />
          <el-date-picker
              class="handle-input mr10"
              v-model="queryStartEndDate"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="handleDateChange"
          >
          </el-date-picker>
          <el-dialog
              v-model="dialogVisible"
              title="温馨提示"
              width="30%"
              :before-close="handleClose"
          >
            <span>请点击右上角下载中心查看</span>
            <template #footer>
            <span class="dialog-footer">
              <el-button type="primary" @click="dialogVisible = false"
              >OK</el-button
              >
            </span>
            </template>
          </el-dialog>
          <div class="handle-box" style="margin-top: 10px">
            <el-input
                v-model="query.cid"
                placeholder="课时Id"
                class="mr10" style="width: 100px;"
            ></el-input>
            <el-select
                clearable
                v-model="query.classType"
                placeholder="课时类型"
                class="handle-select mr10"
            >
              <el-option
                  v-for="(item, index) in classType"
                  :key="index"
                  :label="item.dictLabel"
                  :value="item.dictLabel"
              ></el-option>
            </el-select>
            <el-input
                v-model="query.studentName"
                placeholder="学生姓名、电话"
                class="handle-input mr10"
            ></el-input>
            <el-input
                v-model="query.orderId"
                placeholder="主、副卡的Id"
                class="handle-input mr10"
            ></el-input>
            <el-select
                v-model="query.studentType"
                placeholder="学员状态"
                clearable
                class="handle-select mr10"
            >
              <el-option key="1" label="正常" value="1"></el-option>
              <el-option key="2" label="试听" value="2"></el-option>
              <el-option key="3" label="异常" value="3"></el-option>
            </el-select>
            <el-input
                v-model="query.submitCoachName"
                placeholder="提交教练"
                class="handle-input mr10"
            ></el-input>
            <el-input
                v-model="query.coachName"
                placeholder="上课教练"
                class="handle-input mr10"
            ></el-input>
            <el-button
                type="primary"

                style="margin-left: 10px"
                @click="handleSearch"
            >搜索
            </el-button>
<!--            <el-button type="info"  @click="reset"-->
<!--            >重置</el-button-->
<!--            >-->
            <el-popconfirm @confirm="exportExcel" title="确定要导出吗？">
              <template #reference>
                <el-button
                    type="primary"
                    v-loading="loading"

                >导出</el-button
                >
              </template>
            </el-popconfirm>
            <el-dialog
                v-model="dialogVisible"
                title="温馨提示"
                width="30%"
                :before-close="handleClose"
            >
              <span>请点击右上角下载中心查看</span>
              <template #footer>
            <span class="dialog-footer">
              <el-button type="primary" @click="dialogVisible = false"
              >OK</el-button
              >
            </span>
              </template>
            </el-dialog>
          </div>
        </div>
        <div>
          <div>
            <el-date-picker
                v-model="value1"
                type="month"
                style="width: 170px;"
                value-format="YYYY-MM"
                placeholder="课时确认导出日期">
            </el-date-picker>
          </div>
            <el-popconfirm @confirm="excellCourse" title="确定要导出吗？">
              <template #reference>
                <el-button
                    style="margin-top: 10px;width: 170px"
                    type="primary"
                    v-loading="loading"
                >课时确认表导出</el-button
                >
              </template>
            </el-popconfirm>
        </div>
      </div>




      <el-table
        :data="tableData"
        border
        v-loading="tableLoading"
        class="table el-table__cell"
        height="606px"
        ref="multipleTable"
        :row-style="{ height: '0' }"
        :cell-style="{ padding: '0' }"
        header-cell-class-name="table-header"
      >
        <el-table-column width="110" prop="id" label="课时ID"/>
        <el-table-column width="110" prop="coachname" label="提交老师"/>

        <el-table-column
          width="220"
          show-overflow-tooltip="true"
          align="left"
          label="上课校区"
        >
          <template #default="scope">
            <div
              style="
                text-align: left;
                overflow: hidden;
                width: 210px;
                text-overflow: ellipsis;
              "
            >
              {{ scope.row.regionName }} - {{ scope.row.flagName }} -
              {{ scope.row.campusName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="120" label="上课教练">
          <template #default="scope">
            <el-popover
              v-if="scope.row.teacherList.length > 1"
              placement="right"
              :width="200"
              trigger="hover"
            >
              <div
                style="text-align: center"
                v-for="(item, index) in scope.row.teacherList" :key="index"
              >
                <div style="text-align: left; margin: 6px">
                  {{ item.lookCourse == 0 ? "上课老师" : item.lookCourse == 1 ? "看课老师" : "组教老师" }}
                  ：{{ item.name }}
                </div>
              </div>
              <template #reference>
                <el-tag type="success" style="width: 80px; text-align: center">
                  {{ scope.row.teacherList[0].name }}
                </el-tag>
              </template>
            </el-popover>
            <el-tag v-else style="width: 80px; text-align: center">{{
              scope.row.teacherList[0]?.name
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="100" prop="ctype" label="上课类型">
        </el-table-column>
        <el-table-column
          width="160"
          show-overflow-tooltip="true"
          prop="coursedate"
          label="上课时间"
        >
        </el-table-column>
        <el-table-column width="130" label="课程学员" show-overflow-tooltip>
          <template #default="scope">
            <div
              style="
                background-color: #ecf5ff;
                border: 1px solid #c6e2ff;
                border-radius: 6px;
                padding: 4px;
              "
              @click="
                selectStudent(scope.row, scope.row), (studentDialog = true)
              "
            >
              <span style="color: #409eff; font-weight: bold">{{
                scope.row.snumber
              }}</span>
              |
              <span style="color: #e6a23c">{{
                scope.row.normalStudent.length
              }}</span>
              /
              <span style="color: #f56c6c">{{
                scope.row.abnormalStudent.length
              }}</span>
              /
              <span style="color: #67c23a">{{
                scope.row.auditionStudent.length
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="课程图片" width="100">
          <template v-slot="products">
            <el-image
              class="code"
              hide-on-click-modal
              :src="JSON.parse(products.row.cimg)[0]"
              :preview-src-list="JSON.parse(products.row.cimg)"
            >
              <template #error>
                <div class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </template>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          width="160"
          show-overflow-tooltip="true"
          prop="adddate"
          label="添加时间"
        >
        </el-table-column>
        <el-table-column show-overflow-tooltip="true" prop="note" label="备注">
        </el-table-column>
        <el-table-column label="操作" width="100" align="center" fixed="right">
          <template #default="scope">
            <div
              style="
                display: flex;
                justify-content: space-around;
                font-size: 12px;
              "
            >
              <el-button
                size="mini"
                type="text"
                class="blue"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑
              </el-button>
              <!--            <el-button-->
              <!--                size="mini"-->
              <!--                type="text"-->
              <!--                class="red"-->
              <!--                @click="handleDelete(scope.$index, scope.row)"-->
              <!--              >删除-->
              <!--            </el-button>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class="pagination">
        <el-pagination
          border
          layout="total, prev, pager, next"
          :current-page="query.page"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog title="课程学员" v-model="studentDialog" width="1210px" center @close="clearCoach">
      <el-tabs
        v-model="activeName"
        stretch="true"
        class="demo-tabs"
        @tab-click="handleClick"
      >
        <el-tab-pane label="课程信息" name="courses" style="position: relative">
          <div style="height: 438px;margin-top: 20px;padding:0 10px;display: flex;justify-content: space-between">
            <el-form>
              <el-row>
                <el-col :span="11">
                  <el-form-item label-width="90px" label="提交老师">
                    <el-input disabled v-model="param.coachname"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <el-form-item label-width="90px" label="上课校区">
                    <el-cascader
                        filterable
                        :options="submitCampusAreaList"
                        :show-all-levels="false"
                        v-model="param.campusId"
                        :props="{value: 'id',label: 'name'}"
                    >
                      <template #default="{ node , data }">
                        <span>{{data.name}}</span>
                        <span v-if="data.flag == 1"> - 中招</span>
                        <span v-if="data.flag == 2"> - 贝体</span>
                        <span v-if="data.flag == 4"> - 高考</span>
                        <span v-if="!node.isLeaf"></span>
                      </template>
                    </el-cascader>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label-width="90px" label="上课类型">
                    <el-input disabled v-model="param.ctype"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="11">
                  <el-form-item label-width="90px" label="上课时间">
                    <el-date-picker style="" disabled
                                    v-model="param.courseDate"
                                    format="YYYY-MM-DD hh:mm:ss"
                                    value-format="YYYY-MM-DD hh:mm:ss"
                                    @blur="changeTime"
                                    type="datetime"/>
                  </el-form-item>
                </el-col>
                <el-col :span="11">
                  <el-form-item label-width="90px" label="创建时间">
                    <el-date-picker style="" disabled
                                    v-model="param.adddate"
                                    format="YYYY-MM-DD hh:mm:ss"
                                    value-format="YYYY-MM-DD hh:mm:ss"
                                    @blur="changeTime"
                                    type="datetime"/>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="22">
                  <el-form-item label-width="90px" label="备注说明">
                    <el-input style="width: 488px;" :rows="3" v-model="param.note" disabled
                              prop="briefing" placeholder="请输入描述信息" show-word-limit type="textarea"/>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="teacher-list">
              <div style="text-align: center">
                <h3>上课老师</h3>
              </div>
                <el-popover placement="right" :width="610" trigger="click">
                  <template #reference>
                    <el-button style="margin-bottom: 10px" type="primary">添加</el-button>
                  </template>
                  <div>
                    <el-input v-model="form.keyword" style="width: 140px" placeholder="请输入姓名"/>
                    <el-button
                        type="primary"
                        style="margin: 10px"
                        @click="selectCoach">查询</el-button>
                  </div>
                  <el-table  height="300px" :data="AllCaochList">
                    <el-table-column width="100" show-overflow-tooltip property="coachId" label="id" />
                    <el-table-column width="120" show-overflow-tooltip property="coachName" label="姓名" />
                    <el-table-column width="140" show-overflow-tooltip property="coachPhone" label="电话" />
                    <el-table-column width="140" show-overflow-tooltip property="campusName" label="校区" />
                    <el-table-column label="操作" width="80" fixed="right" align="center">
                      <template #default="scope">
                        <el-popconfirm
                            @confirm="checkedCoach(scope.row)"
                            iconColor="red"
                            title="确定添加吗？"
                        >
                          <template #reference>
                            <el-button style="font-size: 12px" type="primary" text="danger">添加</el-button>
                          </template>
                        </el-popconfirm>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-popover>
              <el-table :data="teacherList">
                <el-table-column width="90" property="coachId" label="id" />
                <el-table-column width="160" show-overflow-tooltip property="name" label="姓名" />
                <el-table-column width="150" show-overflow-tooltip property="relationMatter" label="类型">
                  <template #default="scope">
                    <el-select v-model="scope.row.lookCourse" placeholder="请选择">
                      <el-option label="上课老师" :value="0"/>
                      <el-option label="看课老师" :value="1"/>
                      <el-option label="组教老师" :value="2"/>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="100" fixed="right" align="center">
                  <template #default="scope">
                    <el-popconfirm
                        @confirm="deleteCoach(scope)"
                        iconColor="red"
                        title="确定要删除吗？"
                    >
                      <template #reference>
                        <el-button style="font-size: 12px" type="danger" text="danger">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div style="text-align: center">
            <el-button type="primary" style="width: 180px;padding: 20px 0" round @click="ensure">更 新</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="正常学员" name="normal">
          <div style="height: 500px; overflow-x: hidden">
            <!--学员展示-->
            <div style="display: flex; flex-wrap: wrap">
              <div v-for="(item, index) in course.normalStudent" :key="item.id">
                <div
                  class="student"
                  :style="
                    item.cardType == 1
                      ? 'background-color:#fdf6ec;color:#f6b334 '
                      : 'background-color:#d9ecff;color:#409eff '
                  "
                >
                  <div>姓名：{{ item.name }}</div>
                  <div style="margin: 3px 0">主卡Id：{{ item.orderId }}</div>
                  <div>卡片名称：{{ item.cardName }}</div>
                  <el-popconfirm
                    @confirm="deleteStudent(item, index)"
                    icon="el-icon-info"
                    iconColor="red"
                    title="确定要删除此学员吗？"
                  >
                    <template #reference>
                      <div class="deleteStudent">×</div>
                    </template>
                  </el-popconfirm>
                </div>
              </div>
              <div @click="addNormalStudentList" class="addStudent student">
                <div>+</div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="异常学员" name="exception">
          <div
            v-if="course.abnormalStudent.length > 0"
            style="height: 500px; display: flex; flex-wrap: wrap"
          >
            <div v-for="(item, index) in course.abnormalStudent" :key="item.id">
              <div
                class="student"
                style="background-color: #e9e9eb; color: #73767a"
              >
                <div>姓名：{{ item.name }}</div>
                <div style="margin: 3px 0">
                  异常原因：{{ item.abnormalInfo }}
                </div>
                <el-popconfirm
                  @confirm="deleteStudent(item, index, 'normal')"
                  icon="el-icon-info"
                  iconColor="red"
                  title="确定要删除此学员吗？"
                >
                  <template #reference>
                    <div class="deleteStudent">×</div>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </div>
          <el-empty v-else description="暂无数据" style="height: 500px" />
        </el-tab-pane>
        <el-tab-pane label="试听学员" name="trial">
          <div style="height: 500px">
            <div style="display: flex; flex-wrap: wrap">
              <div
                v-for="(item, index) in course.auditionStudent"
                :key="item.id"
              >
                <div
                  class="student"
                  style="background-color: #e1f3d8; color: #67c23a"
                >
                  <div>姓名：{{ item.name }}</div>
                  <div style="margin-top: 3px">
                    电话：{{ item.abnormalInfo }}
                  </div>
                  <el-popconfirm
                    @confirm="deleteStudent(item, index)"
                    icon="el-icon-info"
                    iconColor="red"
                    title="确定要删除此学员吗？"
                  >
                    <template #reference>
                      <div class="deleteStudent">×</div>
                    </template>
                  </el-popconfirm>
                </div>
              </div>

              <el-popover placement="bottom-start" :width="150" trigger="click">
                <template #reference>
                  <div class="student addStudent">
                    <div>+</div>
                  </div>
                </template>
                <div style="text-align: center">
                  <el-input
                    v-model="addTrialStudent.name"
                    placeholder="学员姓名"
                  />
                  <el-input
                    style="margin: 14px 0 20px 0"
                    v-model="addTrialStudent.abnormalInfo"
                    placeholder="学员电话"
                  />
                  <el-button
                    @click="createTrialStudent"
                    size="small"
                    type="primary"
                    round
                    >确定</el-button
                  >
                </div>
              </el-popover>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <el-dialog
          v-model="addNormalStudentDialog"
          width="1150px"
          :title="dialogTitle"
          append-to-body
          @close="clearList"
        >
          <div style="height: 480px" v-loading="loadingStudent">
            <div>
              <el-input
                v-model="addStudentName"
                style="width: 160px"
                placeholder="学员姓名"
              />
              <el-button
                type="primary"
                icon="Search"
                style="margin: 20px"
                @click="remoteMethod('S')"
                >查询</el-button
              >
            </div>
            <div style="display: flex; flex-wrap: nowrap">
              <!-- studentsArray -->
              <el-table
                :data="studentsArray"
                height="380px"
                :cell-style="{
                  color: '#909399',
                  fontSize: '12px',
                  padding: 6 + 'px',
                }"
                :row-style="rowStyle"
                style="width: 100%"
                border
              >
                <el-table-column prop="orderId" label="订单ID" width="100">
                  <template #default="scope">
                    <div v-if="scope.row.orderId != null">
                      {{ scope.row.cardType == 1 ? "主：" : "副："
                      }}{{ scope.row.orderId }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="学员姓名" width="110" />
                <el-table-column prop="phone" label="手机号码" width="110" />
                <el-table-column
                  prop="cardName"
                  width="140"
                  label="卡片名称"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    {{ scope.row.cardName }} - Id:{{ scope.row.cardId }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="remaining"
                  width="80"
                  label="剩余课时"
                />
                <el-table-column
                  prop="startDate"
                  width="150"
                  label="开始日期"
                  show-overflow-tooltip
                />
                <el-table-column
                  prop="endDate"
                  width="150"
                  label="结束日期"
                  show-overflow-tooltip
                />
                <el-table-column
                  prop="closedTime"
                  width="150"
                  label="缴费日期"
                  show-overflow-tooltip
                />
                <el-table-column prop="state" label="状态" width="80" />
                <el-table-column
                  prop="note"
                  label="备注"
                  width="180"
                  show-overflow-tooltip
                />
                <el-table-column label="操作" width="80" fixed="right">
                  <template #default="scope">
                    <el-button
                      size="mini"
                      type="text"
                      :disabled="scope.row.orderId == null"
                      @click="checkedStudent(scope.row)"
                    >
                      添加
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>

              <!-- <div @click="checkedStudent(index)" class="student"
                  :style="item.typename == 'main' ? 'background-color:#fdf6ec;color:#f6b334 ' : 'background-color:#d9ecff;color:#409eff '">
                  <div>{{ item.typename == 'main' ? '主Id：': '副Id：' }}{{item.id}}</div>
                  <div style="margin: 3px 0">学员姓名：{{ item.name }}</div>
                  <div style="margin: 3px 0">学员电话：{{ item.phone }}</div>
                  <div>卡片名称：{{ item.cardType }}</div>
                </div> -->
            </div>
          </div>
        </el-dialog>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  page,
  deleteStudent,
  deleteRecord,
  excel,
  coursesAddStudent,
  upCourseTeacherAndCampus,
  exportCoursesRecordStatistics
} from "@/api/coursesRecord";
import {
  pageList,
  // aaaaaa
} from "@/api/coach";
import { listCampusTree } from "@/api/area.js";
import { classType } from "../../api/coursesRecord";
import { courseSelectStudent } from "@/api/order";
import selectArea from "../../components/SelectArea";
// import { pageList } from '../../api/competeEnterprise';
export default {
  components: {
    selectArea,
  },
  name: "CoursesRecord",
  data() {
    return {
      value1:'',
      addStudents: {
        time: "",
      },
      loadingStudent: false,
      form: {
        areaList: [],
        keyword: "",
        pageIndex: 1,
        pageSize: 10,
        state: "",
        type: "",
      },
      AllCaochList: [],
      teacherList:[],
      dialogTitle: "",
      submitCampusAreaList: [],
      coursesInfomation: {},
      coachInfoList: [],
      lookTeachList:[],
      activeName: "normal",
      clear: true,
      dialogVisible: false,
      tableData: [],
      pageTotal: null,
      queryStartEndDate: [],
      startDateAndEndDate: null,
      regionList: [],
      campusList: [],
      classType: [],
      loading: false,
      course: {},
      addStudentName: "",
      studentsArray: [],
      query: {
        studentType: "",
        coachName: "",
        studentName: "",
        areaList: [],
        classType: "",
        page: 1,
        submitCoachName:"",
        cid:null,
        orderId:null,
        pageSize: 10,
        startDate: null,
        endDate: null,
      },
      param: {
        campusId: null,
        courseRecordId: null,
        teacherIdList: [],
        courseDate:'',
        adddate: null
      },
      studentDialog: false, //正常学员
      addNormalStudentDialog: false, //添加正常学员
      addTrialStudentDialog: false, //添加试听学员
      addTrialStudent: {
        name: "",
        studentType: 2,
        abnormalInfo: "",
        coursesId: null,
      },
      tableLoading: false,
      campusID: "",
    };
  },
  methods: {
    //弹出框关闭清空
    clearCoach() {
      this.course = {};
      this.course.abnormalStudent = [];
      this.course.auditionStudent = [];
      this.coachInfoList = [];
      this.lookTeachList = []
      this.param.courseRecordId = null;
      this.param.courseDate = ''
    },
    //课时确认导出
    excellCourse(){
      if(this.value1){
        let arr = this.value1.split('-')
        let ojb  = {
          year:arr[0],
          month:arr[1],
          areaList:this.query.areaList,
          // flag:
        }
       exportCoursesRecordStatistics(ojb)
      }else{
        this.$message.error('请先选择年-月！！！')
      }
    },
    ensure() {
      if (Array.isArray(this.param.campusId)) {
        let campusId =this.param.campusId[this.param.campusId.length - 1];
        this.param.campusId = campusId;
      }
      this.param.teacherList = this.teacherList;
      upCourseTeacherAndCampus(this.param).then((res) => {
        this.studentDialog = false;
        this.param.teacherIdList = [];
        if (res.code == 200) {
          this.$message.success("修改成功！");
          this.getData();
        } else {
          this.$message.error("修改失败！");
        }
      });
    },
    changeTime(){
      if(this.param.courseDate){
        this.param.courseDate = this.dateFormats(this.param.courseDate)
      }
    },

    dateFormats(val) {
      var date = new Date(val);
      var sign2 = ":";
      var year = date.getFullYear() // 年
      var month = date.getMonth() + 1; // 月
      var day = date.getDate(); // 日
      var hour = date.getHours(); // 时
      var minutes = date.getMinutes(); // 分
      var seconds = date.getSeconds() //秒
      if (month >= 1 && month <= 9) {
          month = "0" + month;
      }
      if (day >= 0 && day <= 9) {
          day = "0" + day;
      }
      if (hour >= 0 && hour <= 9) {
          hour = "0" + hour;
      }
      if (minutes >= 0 && minutes <= 9) {
          minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
          seconds = "0" + seconds;
      }
      return year + "-" + month + "-" + day + " " + hour + sign2 + minutes + sign2 + seconds;
    },
    clearList() {
      this.addStudentName = "";
      this.studentsArray = [];
    },
    rowStyle({ row }) {
      if (row.cardType == 1) {
        return {
          backgroundColor: " #fdf6ec",
          height: 10 + "px",
        };
      } else {
        return {
          backgroundColor: "#ecf5ff",
        };
      }
    },
    addCoach() {
      this.dialogTitle = "添加教练";
      this.addNormalStudentDialog = true;
    },
    addNormalStudentList() {
      this.dialogTitle = "添加正常学员";
      this.addNormalStudentDialog = true;
    },
    deleteCoach(item) {
      if (this.teacherList.length == 1) {
        this.$message.error("课程不能没有上课老师！");
      } else {
        this.teacherList.splice(item.$index, 1);
      }
    },
    getAreaList(typeId) {
      listCampusTree(typeId).then((val) => {
        if (val.code == 200) {
          this.getTypeList(val.data);
          this.submitCampusAreaList = val.data;
        }
      });
    },
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach((items) => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    //编辑按钮
    handleEdit(index, row) {
      this.activeName = "courses";
      this.course = row;
      this.studentDialog = true;
      this.teacherList = row.teacherList;
      this.param.courseRecordId = row.id;
      this.param.campusId = row.campusId;
      this.param.courseDate = row.coursedate
      this.param.adddate = row.adddate
      this.param.note = row.note
      this.param.coachname = row.coachname
      this.param.ctype = row.ctype
    },
    selectCoach(){
      pageList(this.form).then((res) => {
        if (res.code == 200) {
          this.AllCaochList = res.data.records;
        } else {
          this.$message.error("查询中出现错误了！");
        }
      });
    },
    remoteMethod() {
      this.loadingStudent = true;
      if (this.addStudentName == "") {
        this.$message.warning("请输入学员姓名！");
        this.loadingStudent = false;
        return;
      }
      var id = this.course.id;
      courseSelectStudent(id, this.addStudentName).then((res) => {
        if (res.code == 200) {
          this.loadingStudent = false;
          this.studentsArray = res.data.data;
        } else {
          this.$message.error("查询中出现错误了！");
        }
      });
    },
    checkedCoach(coach){
      let item = {
        coachId: coach.coachId,
        name: coach.coachName,
        lookCourse:0
      };
      this.teacherList.push(item);
    },
    checkedStudent(val) {
      val = JSON.parse(JSON.stringify(val));
      let student = {
        name: val.name,
        cardName: val.cardName,
        cardType: val.cardType,
        orderId: val.orderId,
      };
      this.addStudents.name = val.name;
      this.addStudents.phone = val.phone;
      this.addStudents.abnormalInfo = val.note;
      this.addStudents.cardType = val.cardName;
      this.addStudents.id = val.orderId;
      this.addStudents.coursesId = this.course.id;
      this.addStudents.type = val.cardType;
      this.addStudents = Object.assign({}, this.addStudents);
      this.addStudents.studentType = 1;
      coursesAddStudent(this.addStudents).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功");
          this.addNormalStudentDialog = false;
          this.course.normalStudent.push(student);
          this.studentsArray = [];
          this.getData();
        } else {
          this.$message.error("操作失败");
          this.otherStudentsDialog = false;
        }
        this.getData();
      });
    },
    createTrialStudent() {
      this.addTrialStudent.coursesId = this.course.id;
      coursesAddStudent(this.addTrialStudent).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功");
          this.getData();
        } else {
          this.$message.error("删除失败");
          this.otherStudentsDialog = false;
        }
        this.getData();
      });
    },
    getData() {
      this.tableLoading = true;
      page(this.query).then((val) => {
        this.tableLoading = false;
        if (val.code == 200) {
          this.tableData = val.data.records;
          this.pageTotal = val.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      });
    },
    deleteStudent(item, index, stu) {
      var id = item.id;
      var recerd = item.courseRecordId;
      deleteStudent(id, recerd).then((val) => {
        if (val.code == 200) {
          this.$message.success("删除" + item.name + "学员成功");
          if (stu === "normal") {
            this.NormalStudentsList.splice(index, 1);
          } else {
            this.course.normalStudent.splice(index, 1);
          }
          // console.log(this);
          this.getData();
        } else {
          this.$message.error("删除失败");
        }
      });
    },
    getAreaListId(val) {
      this.activeName = "normal";
      this.query.areaList = val;
    },
    handleDelete(index, item) {
      var id = item.id;
      // 二次确认删除
      this.$confirm("确定要删除" + item.coachname + "提交的课时记录吗? ", {
        type: "warning",
      })
        .then(() => {
          deleteRecord(id).then((res) => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              this.tableData.splice(index, 1);
              this.getData();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    normalStudent(item, val) {
      this.NormalStudentsList = [];
      this.NormalStudentsList = val;
      this.students.student = val;
      this.students.item = item;
    },
    selectStudent(val) {
      this.course = val;
      this.coachInfoList = val.teacherList;
      this.lookTeachList = val.lookTeacherList
      this.param.courseRecordId = val.id;
      this.param.courseDate = val.coursedate
    },
    // 选中城市  渲染校区
    selectCity1(val) {
      this.query.campusId = null;
      if (JSON.stringify(this.regionList[val])) {
        this.campusList = JSON.parse(
          JSON.stringify(this.regionList[val])
        ).campuses;
      }
    },
    // 触发搜索按钮
    handleSearch() {
      if (this.queryStartEndDate && this.queryStartEndDate.length >= 2) {
        this.query.startDate = this.dateFormat(this.queryStartEndDate[0]);
        this.query.endDate = this.dateFormat(this.queryStartEndDate[1]);
      }else {
        this.query.startDate = null;
        this.query.endDate = null;
      }
      this.query.page = 1;
      this.getData();
    },
    //导出
    exportExcel() {
      let date = this.queryStartEndDate;
      // 校验日期跨度是否超过一个月
      if (date && date.length === 2) {
        this.query.startDate = this.dateFormat(date[0]);
        this.query.endDate = this.dateFormat(date[1]);
        const start = new Date(date[0]).getTime();
        const end = new Date(date[1]).getTime();
        const oneMonth = 30 * 24 * 60 * 60 * 1000; // 假设一个月为30天
        if (end - start > oneMonth) {
          this.$message.warning('时间跨度超出30天，导出时间较久，请耐心等待！');
        }
      }else {
        this.query.startDate = null;
        this.query.endDate = null;
      }
      excel(this.query);
    },

    /**
     * 分页
     */
    handlePageChange(val) {
      this.query.page = val;
      this.getData();
    }, // 重置
    reset() {
      this.clear = !this.clear;
      this.query.areaList = [];
      this.queryStartEndDate = [];
      this.query.startDate = "";
      this.query.endDate = "";
      this.query.coachName = null;
      this.query.coachName = "";
      this.query.studentName = "";
      this.query.studentType = "";
      this.query.classType = "";
      this.query.campusString = "";
      this.query.page = 1;
      this.getData();
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
    dateFormatC(val) {
      var myDate = new Date(val);
      var year = myDate.getFullYear(); //年
      var month = myDate.getMonth() + 1; //月
      var day = myDate.getDate(); //日
      var hh = myDate.getHours(); //时
      var mm = myDate.getMinutes();
      var ss = myDate.getSeconds();
      const fname =
        "课时表" +
        year +
        "年" +
        month +
        "月" +
        day +
        "日" +
        hh +
        "时" +
        mm +
        "分" +
        ss +
        "秒";
      return fname;
    },
  },
  /**
   * 加载页面
   */
  created() {
    // aaaaaa().then(res=>{
    //   console.log(res)
    // })
    this.getAreaList();
    // listCampusTree
    this.getData();
    classType().then((res) => {
      if (res.code == 200) {
        this.classType = res.data;
      } else {
        this.$message.error("获取数据失败");
      }
    });
  },
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 130px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 14px;
}

.student {
  width: 226px;
  padding: 14px 16px;
  margin: 10px;
  border-radius: 10px;
  font-size: 12px;
  position: relative;
}
.coach {
  width: 206px;
  padding: 14px 16px;
  margin: 10px;
  border-radius: 10px;
  font-size: 12px;
  position: relative;
}
.coachList {
  width: 206px;
  padding: 14px 16px;
  margin: 10px;
  border-radius: 10px;
  font-size: 12px;
  position: relative;
}
.deleteStudent {
  cursor: pointer;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  line-height: 15px;
  font-size: 20px;
  text-align: center;
  position: absolute;
  top: 8px;
  right: 8px;
  color: red;
}
.flex_sparound {
  display: flex;
  justify-content: space-around;
}
.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.addStudent {
  border: 1px dashed #909399;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #909399;
  cursor: pointer;
}
.code {
  margin-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
.coach-item{
  background-color: #F5F7FA;
  padding:10px 16px;
  margin: 0 10px;
  text-align: left;
  border-radius: 10px;
}
.el-table__cell {
  position: static !important;
}

.add-coach {
  border: 1px dashed #909399;
  width: 100px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.teacher-list{
  width: 500px;
  background-color: #FAFAFA;padding: 14px 20px 20px 20px;border-radius: 20px;
  height: 340px;
}
</style>
<style  type="less">
/* CJL TEST
1.重写plus的image图片预览大图的的position定位样式为static !important;
2.需在el-table中手动添加要覆盖的class名 如el-table__cell
3.此css代码需放在全局的less的style节点中 */
.el-table__cell {
  position: static !important;
}
</style>
